import { gql } from '@apollo/client';

const PROFILE_FRAGMENT = gql`
  fragment profile on Profile {
    id
    name
    firstName
    lastName
    avatar {
      id
      uuid
    }
  }
`;
const USER_FRAGMENT = gql`
  fragment user on User {
    id
    roles
    email
    profile {
      id
      ...profile
    }
    intercomHash
    intercomExternalId
    helpscoutSignature
  }
  ${PROFILE_FRAGMENT}
`;

const SESSION_FRAGMENT = gql`
  fragment session on Session {
    id
    token
    isNew
    returnTo
    user {
      id
      ...user
    }
  }
  ${USER_FRAGMENT}
`;

export const AUTHENTICATE_WITH_EMAIL_MUTATION = gql`
  mutation AuthSignInWithEmail(
    $email: String!
    $password: String!
    $returnTo: String
    $multipass: Boolean
  ) {
    authenticateWithEmail(
      email: $email
      password: $password
      returnTo: $returnTo
      multipass: $multipass
    ) {
      id
      ...session
    }
  }
  ${SESSION_FRAGMENT}
`;

export const AUTHENTICATE_WITH_MAGIC_TOKEN_MUTATION = gql`
  mutation AuthenticateWithMagicToken($token: String!) {
    authenticateWithMagicToken(token: $token) {
      id
      ...session
    }
  }
  ${SESSION_FRAGMENT}
`;

export const VIEWER_QUERY = gql`
  query CurrentUserQuery($impersonatorToken: String, $impersonating: Boolean = false) {
    viewer {
      id
      ...user
    }
    impersonator(token: $impersonatorToken) @include(if: $impersonating) {
      id
      profile {
        id
        ...profile
      }
    }
  }
  ${PROFILE_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const IMPERSONATOR_QUERY = gql`
  query AuthImpersonatorQuery($impersonatorToken: String, $impersonating: Boolean = false) {
    impersonator(token: $impersonatorToken) @include(if: $impersonating) {
      id
      profile {
        id
        avatar {
          id
          uuid
        }
      }
    }
  }
`;

export const REGISTRATION_MUTATION = gql`
  mutation Register(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $signupType: SignupType!
    $school: String
    $basketToken: String
    $optInResponses: JSON
    $returnTo: String
    $multipass: Boolean
  ) {
    basketToken @client @export(as: "basketToken")
    register(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      signupType: $signupType
      school: $school
      basketUuid: $basketToken
      optInResponses: $optInResponses
      returnTo: $returnTo
      multipass: $multipass
    ) {
      id
      ...session
    }
  }
  ${SESSION_FRAGMENT}
`;

export const AUTHENTICATE_WITH_FACEBOOK_MUTATION = gql`
  mutation AuthenticateWithFacebook(
    $signedRequest: String!
    $signupType: SignupType!
    $school: String
    $basketToken: String
    $returnTo: String
    $multipass: Boolean
  ) {
    basketToken @client @export(as: "basketToken")
    authenticateWithFacebook(
      signedRequest: $signedRequest
      signupType: $signupType
      school: $school
      basketUuid: $basketToken
      returnTo: $returnTo
      multipass: $multipass
    ) {
      id
      ...session
    }
  }
  ${SESSION_FRAGMENT}
`;

export const NEW_USER_QUERY = gql`
  query NewUserQuery {
    user {
      id
      optInResponses {
        id
        optIn {
          id
          key
          title
        }
        response
      }
    }
  }
`;

export const REDEEM_USER_INVITE_MUTATION = gql`
  mutation RedeemUserInvite($token: String!, $password: String!, $profile: ProfileInput!) {
    redeemUserInvite(token: $token, password: $password, profile: $profile) {
      id
      ...session
    }
  }
  ${SESSION_FRAGMENT}
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $resetToken: String!,
    $newPassword: String!,
    $returnTo: String,
    $multipass: Boolean
  ) {
    resetPassword(
      resetToken: $resetToken
      newPassword: $newPassword
      returnTo: $returnTo
      multipass: $multipass
    ) {
      id
      ...session
    }
  }
  ${SESSION_FRAGMENT}
`;

export const RESET_PASSWORD_REQUEST_MUTATION = gql`
  mutation ResetPasswordRequest(
      $email: String!,
      $returnTo: String
    ) {
    resetPasswordRequest(
      email: $email,
      returnTo: $returnTo
    )
  }
`;
