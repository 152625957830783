import React from 'react';
import { Router, browserHistory } from 'react-router-v3';
import PropTypes from 'prop-types';

const routeChange = () => {
  window.scrollTo(0, 0);
};

const App = ({ routes }) => {
  return (
    <Router history={browserHistory} onUpdate={routeChange}>
      {routes}
    </Router>
  );
};

App.propTypes = {
  routes: PropTypes.any,
};

export default App;
