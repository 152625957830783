import React from 'react';
import { Route, IndexRoute, Redirect } from 'react-router-v3';
import { AuthenticatedOnly, isSuperUser } from 'hooks/user';
import { WithFeatureFlag } from 'hooks/configuration';

import {
  Shell,
  Authentication,
  Classroom,
  Profile,
  Community,
  CommunityCategory,
  CommunityNewQuestion,
  CommunityQuestion,
  BlogListing,
  BlogPostDetail,
  CustomPage,
  TermsAndConditions,
  PrivacyPolicy,
  CommunityGuidelines,
  PageNotFound,
  ContactUs,
  Order,
  OrderReceipt,
  OrderProcessing,
  App,
  Home,
  SchoolCourses,
  ProductDetail,
  Basket,
  Checkout,
  BasketItems,
  BasketChaser,
  Experts,
  Expert,
  About,
  School,
  Search,
  ProductCodeRedeemer,
  UnregisteredUserConfirm,
  Join,
  SignIn,
  BlackFridayPage,
  ValentinesPage,
  ChristmasPage,
  FathersDayPage,
  Perkbox,
  TheTimes,
  TheTimesGardening,
  TheTimesFoodAndDrink,
  TheTimesWellbeing,
  Telegraph,
  GourmetSociety,
  TasteCard,
  Cssc,
  BornFreeFoundation,
  RiverCottageLandingPage,
  Life,
  GiftCard,
  Reseller,
  ResellerProductDetail,
  Inline,
} from './routes';

const Router = (
  <>
    <Route path="/inline/*" component={Inline} />
    <Route path="/auth/*" component={Authentication} />
    <Route path="/classrooms/:classroomId(/**)" component={Classroom} />
    <Route path="/classrooms" component={Classroom} />
    <Route path="/" component={App}>
      <IndexRoute component={WithFeatureFlag('homepage', Home, 'homepageRedirect')} />
      <Route path="resellers" component={Reseller} secure />
      <Route
        path="resellers/:schoolSlug/courses/:productSlug"
        component={ResellerProductDetail}
        secure
      />
      <Route path="join" component={Join} />
      <Route path="signin" component={SignIn} />
      <Route path="perkbox" component={WithFeatureFlag('landings', Perkbox)} app />
      <Route path="gourmetsociety" component={WithFeatureFlag('landings', GourmetSociety)} app />
      <Route path="tastecard" component={WithFeatureFlag('landings', TasteCard)} app />
      <Route path="cssc" component={WithFeatureFlag('landings', Cssc)} app />
      <Route
        path="rivercottage"
        component={WithFeatureFlag('landings', RiverCottageLandingPage)}
        app
      />
      <Route path="thetimes">
        <IndexRoute component={WithFeatureFlag('landings', TheTimes)} app />
        <Route path="gardening" component={WithFeatureFlag('landings', TheTimesGardening)} app />
        <Route path="wellbeing" component={WithFeatureFlag('landings', TheTimesWellbeing)} app />
        <Route
          path="foodanddrink"
          component={WithFeatureFlag('landings', TheTimesFoodAndDrink)}
          app
        />
      </Route>
      <Route path="telegraph" component={WithFeatureFlag('landings', Telegraph)} app />
      <Route
        path="born-free-foundation"
        component={WithFeatureFlag('landings', BornFreeFoundation)}
        app
      />
      <Route path="life" component={WithFeatureFlag('landings', Life)} app />
      <Route path="blackfriday" component={WithFeatureFlag('shop', BlackFridayPage)} />
      <Route path="valentines" component={WithFeatureFlag('shop', ValentinesPage)} />
      <Route path="christmas" component={WithFeatureFlag('shop', ChristmasPage)} />
      <Route path="fathersday" component={WithFeatureFlag('shop', FathersDayPage)} />
      <Route path="about" component={WithFeatureFlag('about', About)} />
      <Route path="search" component={WithFeatureFlag('search', Search)} />
      <Route path="basket" component={WithFeatureFlag('shop', Basket)}>
        <IndexRoute component={BasketItems} />
        <Route path="checkout(/:step)(/:orderId)" component={Checkout} modal secure />
        <Route path=":basketId" component={BasketChaser} />
      </Route>
      <Route path="gifts" component={WithFeatureFlag('giftcards', GiftCard)} />
      <Route path="profile/:userId" component={WithFeatureFlag('profiles', Profile)} app />
      <Route path="experts" component={WithFeatureFlag('experts', Experts)} />
      <Route path="experts/:expertSlug" component={WithFeatureFlag('experts', Expert)} />
      <Route
        path="orders"
        component={WithFeatureFlag('shop', AuthenticatedOnly(Order))}
        modal
        secure
      >
        <Route path=":orderId" component={OrderReceipt} />
        <Route path="processing/:basketId" component={OrderProcessing} />
      </Route>
      <Redirect from="contact" to="/contact-us" />
      <Route path="contact-us" component={ContactUs} />
      <Route path="terms-and-conditions" component={TermsAndConditions} />
      <Route path="privacy-policy" component={PrivacyPolicy} />
      <Route path="community-guidelines" component={CommunityGuidelines} />
      <Route path="redeem" component={ProductCodeRedeemer} />
      <Route path="confirm/:token" component={UnregisteredUserConfirm} />
      <Route path=":schoolSlug" component={WithFeatureFlag('schools', School)}>
        <IndexRoute component={SchoolCourses} />
        <Route path="community">
          <IndexRoute component={Community} />
          <Route path=":categorySlug" component={CommunityCategory} />
          <Route path=":categorySlug/new" component={CommunityNewQuestion} />
          <Route path="questions/:questionSlug" component={CommunityQuestion} />
        </Route>
        <Route path="join" component={Join} />
        <Redirect from="classes" to="/classrooms" />
        <Redirect from="classes/:courseId" to="/classrooms" />
        <Route path="courses/:productSlug">
          <IndexRoute component={ProductDetail} />
        </Route>
        <Route path="blog(/page/:pageNumber)">
          <IndexRoute component={BlogListing} />
          <Route path=":blogPostSlug" component={BlogPostDetail} />
        </Route>
        <Redirect from="assignments/:assignmentId" to="/:schoolSlug" />
        <Route path=":pageSlug" component={CustomPage} />
      </Route>
      <Route path="*" status="404" component={PageNotFound} />
    </Route>
  </>
);

export default Router;
