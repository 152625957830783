import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import createRoutes from '../app/router';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { loadableReady } from '@loadable/component';
import { BrowserRouter } from 'react-router-dom';
import App from '../app';
import { EventBusProvider } from '@lwe/toolkit/utils';
import { ApolloAndPusherProviderWithErrorHandler } from '@lwe/apollo';
import env from '@lwe/toolkit/env';

const BUGSNAG_API_KEY = env('BUGSNAG_API_KEY');
const ENVIRONMENT = env('ENVIRONMENT');

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: ENVIRONMENT,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const reduxLocalstorage = JSON.parse(localStorage.getItem('redux-localstorage'));
if (reduxLocalstorage) {
  if (reduxLocalstorage.auth && reduxLocalstorage.auth.token) {
    localStorage.setItem('authToken', reduxLocalstorage.auth.token);
  }
  localStorage.removeItem('redux-localstorage');
}

const lweBasket = JSON.parse(localStorage.getItem('lwe-basket'));
if (lweBasket) {
  if (lweBasket.basket && lweBasket.basket.uuid) {
    localStorage.setItem('basketToken', lweBasket.basket.uuid);
  }
  localStorage.removeItem('lwe-basket');
}

const node = (routes) => (
  <ErrorBoundary>
    <EventBusProvider>
      <ApolloAndPusherProviderWithErrorHandler>
        <BrowserRouter>
          <App routes={routes} />
        </BrowserRouter>
      </ApolloAndPusherProviderWithErrorHandler>
    </EventBusProvider>
  </ErrorBoundary>
);

let root;

loadableReady(() => {
  root = hydrateRoot(document.getElementById('app'), node(createRoutes));
});

// if (module.hot) {
//   module.hot.accept('../app/router', () => {
//     if (root) {
//       const newRoutes = require('../app/router').default;
//       root.render(node(newRoutes));
//     }
//   });
// }
