import { useEffect } from 'react';
import { withRouter } from 'react-router-v3';

const Redirect = ({ to, router }) => {
  useEffect(() => {
    router.push(to);
  }, [to]);

  return null;
};

export default withRouter(Redirect);
