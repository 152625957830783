import React, { ReactNode, createContext, useContext } from 'react';
import Pusher from 'pusher-js';

const Context = createContext<{ pusher: Pusher | null }>({
  pusher: null,
});

interface ProviderProps {
  pusher: Pusher;
  children?: ReactNode;
}

export const Provider = ({ pusher, children }: ProviderProps) => {
  return <Context.Provider value={{ pusher }}>{children}</Context.Provider>;
};

export const usePusher = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('usePusher must be used within a PusherProvider');
  }

  return context.pusher;
};
