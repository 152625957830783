import React, { useCallback, useMemo, useEffect } from 'react';
import { useMutation, useApolloClient, useQuery, gql } from '@apollo/client';

import { useLogout, useCurrentUser } from '@lwe/toolkit/authentication';

const VIEWER_UPDATED_SUBSCRIPTION = gql`
  subscription ViewerUpdated($userId: ID!) {
    viewerUpdated(userId: $userId) {
      ... on NewPrivateMessage {
        message {
          id
          conversation {
            id
            lastMessageAt
            messagesCount
            recipient {
              id
              profile {
                id
                name
                avatar {
                  id
                  uuid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const withCurrentUser = (Component) => (props) => {
  const { currentUser } = useCurrentUser();
  return <Component currentUser={currentUser} {...props} />;
};

const PASS = () => true;
export const isSuperUser = (user) =>
  ['admin', 'tutor', 'moderator', 'observer'].some((role) => user.roles.includes(role));

export const AuthenticatedOnly =
  (Component, restriction = PASS) =>
  (props) => {
    const { currentUser, loading } = useCurrentUser();
    const { location, router } = props;
    const canAccess = currentUser && restriction(currentUser);

    useEffect(() => {
      if (!loading && !canAccess) {
        router.push({
          pathname: '/auth/signin',
          state: {
            returnTo: location.pathname,
          },
        });
      }
    }, [currentUser, loading, location]);

    if (!canAccess) return null;

    return <Component currentUser={null} {...props} />;
  };

export const useLegacyLogoutAndRedirect = (router) => {
  const logout = useLogout();

  return useCallback(() => {
    logout();
    localStorage.removeItem('basketToken');
    router.push('/');
  }, [logout, router]);
};

const USER_ONLINE_STATUS_FRAGMENT = gql`
  fragment onlineStatus on User {
    id
    online @client
  }
`;

export const useUpdateOnlineStatus = () => {
  const client = useApolloClient();
  return useCallback(
    ({ id }, online = true) => {
      client.writeFragment({
        fragment: USER_ONLINE_STATUS_FRAGMENT,
        id: `User:${id}`,
        data: {
          __typename: 'User',
          id,
          online,
        },
      });
    },
    [client],
  );
};

const REDEEM_GIFT_CARD_MUTATION = gql`
  mutation RedeemGiftCard($code: String!) {
    redeemGiftCard(code: $code) {
      id
      __typename
      ... on GiftCardAmountRedemption {
        accountLedgerEntry {
          id
          account {
            id
            formattedBalance
          }
        }
      }
    }
  }
`;

export const useGiftCardRedeemer = () => {
  const [redeem] = useMutation(REDEEM_GIFT_CARD_MUTATION);
  return useCallback((code) => {
    return redeem({
      variables: {
        code,
      },
    });
  });
};

const REDEEM_PARTNER_CODE_MUTATION = gql`
  mutation RedeemPartnerCode($code: String!) {
    redeemPartnerCode(code: $code) {
      id
    }
  }
`;

export const useRedeemPartnerCode = () => {
  const [redeem] = useMutation(REDEEM_PARTNER_CODE_MUTATION);
  return useCallback((code) => {
    return redeem({
      variables: {
        code,
      },
    });
  });
};
