import React, { ReactNode } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

import { DocumentIcon } from './icons';

const storedDarkModeOption =
  typeof window !== 'undefined' ? localStorage.getItem('darkMode') ?? 'system' : 'system';
const systemIsDark =
  typeof window !== 'undefined' ? window.matchMedia('(prefers-color-scheme: dark)').matches : false;
const isDark = storedDarkModeOption === 'system' ? systemIsDark : storedDarkModeOption === 'dark';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: #FBFBFB;
  }
`;

const Container = styled.div<{ isDark: boolean }>`
  width: 100%;
  height: 100vh;
  transition: background-color 150ms linear;
  background-color: ${({ isDark }) => (isDark ? '#282828' : '#F9F9F9')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-size: 16px;
  line-height: 26px;
  font-family: Helvetica, Arial, sans-serif;
  color: #929292;
  margin: 36px 0 0 0;
  text-align: center;
`;

const Quote = styled.span<{ citation: boolean }>`
  font-weight: bold;
  ${({ citation }) =>
    citation &&
    css`
      &:before,
      &:after {
        content: '"';
      }
    `};
`;

const Citation = styled.span`
  font-weight: normal;
`;

const OptionContainer = styled.div<{ isDark: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 570px;
  padding: 30px;

  & > svg {
    & path,
    & g,
    & polygon {
      fill: ${({ isDark }) => (isDark ? '#4A4A4A' : '#E0E0E0')};
    }
  }
`;

const options: { icon: ReactNode; quote?: string; citation?: string }[] = [
  {
    icon: <DocumentIcon />,
    quote: 'Loading...',
  },
  {
    icon: <DocumentIcon />,
    quote: 'Preparing...',
  },
  {
    icon: <DocumentIcon />,
    quote: 'Getting ready...',
  },
  // {
  //   icon: <PeopleIcon />,
  //   quote: 'Tell me and I forget, teach me and I may remember, involve me and I learn.',
  //   citation: 'Benjamin Franklin',
  // },
  // {
  //   icon: <GlassesIcon />,
  //   quote: 'Learn continually. There’s always ‘one more thing’ to learn!',
  //   citation: 'Steve Jobs',
  // },
  // {
  //   icon: <StudentIcon />,
  //   quote: 'Wisdom is not a product of schooling but of the lifelong attempt to acquire it.',
  //   citation: 'Albert Einstein',
  // },
  // {
  //   icon: <StarIcon />,
  //   quote: 'Learning is a treasure that will follow its owner everywhere.',
  //   citation: 'Chinese Proverb',
  // },
];

const AppLoader = (): JSX.Element => {
  const option = new Date().getMinutes() % Object.keys(options).length;
  const { icon, quote, citation } = options[option];

  return (
    <Container isDark={isDark}>
      <GlobalStyle />
      <OptionContainer isDark={isDark}>
        {icon && icon}
        <Title>
          {quote && <Quote citation={!!citation}>{quote}</Quote>}
          {citation && <Citation> — {citation}</Citation>}
        </Title>
      </OptionContainer>
    </Container>
  );
};

export default AppLoader;
