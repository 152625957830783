import loadable from '@loadable/component';
import { AppLoader } from '@lwe/classroom';

export const Classroom = loadable(() => import('@lwe/classroom'), {
  ssr: false,
  fallback: <AppLoader />,
});

export const Authentication = loadable(() => import('@lwe/toolkit/authentication'), {
  ssr: false,
});

export const Profile = loadable(() => import('components/Profile'));
export const Community = loadable(() =>
  import(/* webpackChunkName: "community" */ 'components/Community'),
);
export const CommunityCategory = loadable(() =>
  import(/* webpackChunkName: "community" */ 'components/Community/components/CommunityCategory'),
);
export const CommunityNewQuestion = loadable(() =>
  import(
    /* webpackChunkName: "community" */ 'components/Community/components/CommunityNewQuestion'
  ),
);
export const CommunityQuestion = loadable(() =>
  import(/* webpackChunkName: "community" */ 'components/Community/components//CommunityQuestion'),
);
export const BlogListing = loadable(() =>
  import(/* webpackChunkName: "blog" */ 'components/BlogListing'),
);
export const BlogPostDetail = loadable(() =>
  import(/* webpackChunkName: "blog" */ 'components/BlogPostDetail'),
);
export const CustomPage = loadable(() => import('components/CustomPage'));
export const TermsAndConditions = loadable(() => import('components/TermsAndConditions'));
export const PrivacyPolicy = loadable(() => import('components/PrivacyPolicy'));
export const CommunityGuidelines = loadable(() => import('components/CommunityGuidelines'));
export const PageNotFound = loadable(() => import('components/PageNotFound'));
export const ContactUs = loadable(() => import('components/ContactUs'));

export const JoinSchool = loadable(() => import('components/JoinSchool'));
export const Join = loadable(() => import('components/Join'));
export const SignIn = loadable(() => import('components/SignIn'));
export const Order = loadable(() => import(/* webpackChunkName: "order" */ 'components/Order'));
export const OrderReceipt = loadable(() =>
  import(/* webpackChunkName: "order" */ 'components/OrderReceipt'),
);
export const OrderProcessing = loadable(() =>
  import(/* webpackChunkName: "order" */ 'components/OrderProcessing'),
);

export const Shell = loadable(() => import(/* webpackChunkName: "shell" */ 'components/Shell'));
export const App = loadable(() => import(/* webpackChunkName: "app" */ 'components/App'));
export const Home = loadable(() => import(/* webpackChunkName: "home" */ 'components/Home'));
export const SchoolCourses = loadable(() => import('components/SchoolCourses'));
export const Courses = loadable(() => import('components/Courses'));
export const ProductDetail = loadable(() => import('components/ProductDetail'));
export const UnregisteredUserConfirm = loadable(() => import('components/UnregisteredUserConfirm'));

export const GiftsGlobal = loadable(() =>
  import(/* webpackChunkName: "gifts" */ 'components/GiftsGlobal'),
);
export const Basket = loadable(() =>
  import(/* webpackChunkName: "checkout" */ 'components/Basket'),
);
export const Checkout = loadable(() =>
  import(/* webpackChunkName: "checkout" */ 'components/Basket/components/Checkout'),
);
export const BasketItems = loadable(() =>
  import(/* webpackChunkName: "checkout" */ 'components/Basket/components/BasketItems'),
);
export const BasketChaser = loadable(() =>
  import(/* webpackChunkName: "checkout" */ 'components/Basket/components/BasketChaser'),
);
export const Experts = loadable(() =>
  import(/* webpackChunkName: "experts" */ 'components/Experts'),
);
export const Expert = loadable(() => import(/* webpackChunkName: "experts" */ 'components/Expert'));
export const About = loadable(() => import('components/About'));
export const School = loadable(() => import('components/School'));
export const Search = loadable(() => import('components/Search'));
export const ProductCodeRedeemer = loadable(() => import('components/ProductCodeRedeemer'));

export const BlackFridayPage = loadable(() => import('components/promos/BlackFridayPage'));
export const ValentinesPage = loadable(() => import('components/promos/ValentinesPage'));
export const ChristmasPage = loadable(() => import('components/promos/ChristmasPage'));
export const FathersDayPage = loadable(() => import('components/promos/FathersDayPage'));
export const Telegraph = loadable(() => import('components/landingPages/Telegraph'));
export const Perkbox = loadable(() => import('components/landingPages/Perkbox'));
export const GourmetSociety = loadable(() => import('components/landingPages/GourmetSociety'));
export const TasteCard = loadable(() => import('components/landingPages/TasteCard'));
export const Cssc = loadable(() => import('components/landingPages/Cssc'));
export const RiverCottageLandingPage = loadable(() =>
  import('components/landingPages/RiverCottageLandingPage'),
);
export const TheTimes = loadable(() => import('components/landingPages/TheTimes'));
export const TheTimesGardening = loadable(() =>
  import('components/landingPages/TheTimesGardening'),
);
export const TheTimesFoodAndDrink = loadable(() =>
  import('components/landingPages/TheTimesFoodAndDrink'),
);
export const TheTimesWellbeing = loadable(() =>
  import('components/landingPages/TheTimesWellbeing'),
);
export const BornFreeFoundation = loadable(() =>
  import('components/landingPages/BornFreeFoundation'),
);

export const Life = loadable(() => import('components/landingPages/Life'));

export const GiftCard = loadable(() => import('components/GiftCard'));

export const Reseller = loadable(() => import('components/Reseller'));
export const ResellerProductDetail = loadable(() => import('components/ResellerProductDetail'));

export const Inline = loadable(
  async () => {
    const { Inline } = await import('@lwe/classroom');
    return (props) => <Inline {...props} />;
  },
  {
    ssr: false,
  },
);
