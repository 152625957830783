//import 'core-js/stable/regexp';

if (!('process' in window)) {
  window.process = {};
}

const main = () => require('./main');

const loadScript = (src, done) => {
  var js = document.createElement('script');
  js.src = src;
  js.onload = () => done();
  document.head.appendChild(js);
};

const bootstrap = () => {
  const polyfills = [];

  if (!window.Promise || !window.Symbol) polyfills.push('default', 'es2015', 'es2016', 'es2017');

  const hasIntersectionObserver =
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype;

  if (!hasIntersectionObserver) polyfills.push('IntersectionObserver', 'IntersectionObserverEntry');

  if (!window.fetch) polyfills.push('fetch');

  //if (!polyfills.length) {
  main();
  //} else {
  //  loadScript(`https://polyfill.io/v3/polyfill.min.js?features=${polyfills.join(',')}`, main);
  //}
};

export default bootstrap();
