import { darken, lighten } from 'polished';

export const colors = {
    brand: 'control',
    'dashboard-header': '#4A4A4A',
    'classroom-header': '#4A4A4A',
    'accent-1': '#C3C682',
    'accent-2': '#88C7E2',
    'accent-3': '#EBC882',
    'accent-4': '#E8ACBF',
    'neutral-1': '#99DBCE',
    'neutral-2': '#EBC882',
    'neutral-3': '#A1CAD1',
    'neutral-4': '#88C7E2',
    'neutral-5': '#E8ACBF',
    'neutral-6': '#E4A29E',
    'neutral-7': '#C3C682',
    'neutral-8': '#B7A1CC',

    'grey-1': { light: '#FFFFFF', dark: '#1C1C1C' },
    'grey-2': { light: '#FBFBFB', dark: '#282828' },
    'grey-3': { light: '#EFEFEF', dark: '#343434' },
    'grey-4': { light: '#E0E0E0', dark: '#4A4A4A' },
    'grey-5': { light: '#BEBEBE', dark: '#6E6E6E' },
    'grey-6': { light: '#929292', dark: '#929292' },
    'grey-7': { light: '#6E6E6E', dark: '#BEBEBE' },
    'grey-8': { light: '#4A4A4A', dark: '#E0E0E0' },
    'grey-9': { light: '#343434', dark: '#EFEFEF' },
    'grey-10': { light: '#282828', dark: '#F9F9F9' },
    'grey-11': { light: '#1C1C1C', dark: '#FBFBFB' },
    'grey-12': { light: '#000000', dark: '#FFFFFF' },

    'status-critical': '#FF4040',
    'status-warning': '#D39F00',
    'status-ok': '#00A042',

    'header-background': {
      dark: 'brand',
      light: 'brand',
    },

    'header-pattern': {
      dark: '',
      light: '',
    },

    background: {
      dark: '#282828',
      light: '#FBFBFB',
    },
    'background-front': {
      dark: '#343434',
      light: '#EFEFEF',
    },
    'background-back': {
      dark: '#4A4A4A',
      light: '#E0E0E0',
    },
    'background-contrast': {
      light: '#33333310',
      dark: '#FFFFFF18',
    },
    'background-highlight': {
      light: '#00000008',
      dark: '#FFFFFF08',
    },
    border: {
      dark: '#BEBEBE',
      light: '#6E6E6E',
    },

    control: {
      dark: '#ECECEC',
      light: '#4A4A4A',
    },
    'control-text': { dark: '#4A4A4A', light: '#F8F8F8' },
    'control-text-weak': {
      dark: lighten(0.2, '#4A4A4A'),
      light: darken(0.2, '#F8F8F8'),
    },
    secondary: '#adadad',
    'secondary-active': darken(0.2, '#adadad'),
    'secondary-hover': darken(0.1, '#adadad'),
    active: {
      light: '#E0E0E0',
      dark: '#4A4A4A',
    },
    hover: {
      dark: darken(0.1, '#ECECEC'),
      light: darken(0.1, '#4A4A4A'),
    },

    text: { light: '#343434', dark: '#EFEFEF' },
    'text-weak': { light: '#929292', dark: '#929292' },
    'text-strong': {
      dark: lighten(0.3, '#F8F8F8'),
      light: darken(0.3, '#444444'),
    },
    heading: {
      dark: '#F8F8F8',
      light: '#4A4A4A',
    },

    focus: {
      dark: 'rgba(239, 239, 239, 0.5)',
      light: 'rgb(52, 52, 52, 0.5)',
    },

    placeholder: { light: '#6E6E6E', dark: '#BEBEBE' },
};
