import React from 'react';
import { useQuery, gql } from '@apollo/client';
import loadable from '@loadable/component';
import Redirect from 'components/Redirect';

export const PageNotFound = loadable(() => import('components/PageNotFound'));

export const CONFIG_QUERY = gql`
  query ConfigQuery {
    configuration {
      id
      slug
      country {
        id
        isoCode
        currency {
          id
          isoCode
        }
      }
      settings {
        productTourWorkspaceCode: setting(key: "product_tour.workspace_code")
        homepageRedirect: setting(key: "homepage.redirect")
        title: setting(key: "title")
        parentUrl: setting(key: "parent.url")
        muxDataEnvKey: setting(key: "mux.data.env.key")
        gtmContainerId: setting(key: "gtm.container.id")
        gtmContainerParams: setting(key: "gtm.container.params")
        intercomAppId: setting(key: "intercom.app.id")
        helpscoutAppId: setting(key: "helpscout.app.id")
        frontendUrl: setting(key: "frontend.url")
        tapfiliateId: setting(key: "tapfiliate.id")
        klaviyoApiKey: setting(key: "klaviyo.api.key")
      }
      features {
        id
        homepage: feature(name: "homepage")
        giftcards: feature(name: "giftcards")
        courses: feature(name: "courses")
        experts: feature(name: "experts")
        profiles: feature(name: "profiles")
        schools: feature(name: "schools")
        shop: feature(name: "shop")
        search: feature(name: "search")
        intercom: feature(name: "intercom")
        helpscout: feature(name: "helpscout")
        about: feature(name: "about")
        landings: feature(name: "landings")
        facebook: feature(name: "facebook")
        signup: feature(name: "signup")
        classroomBeta: feature(name: "classroom_beta")
        cpd: feature(name: "cpd")
      }
    }
  }
`;

export const useConfiguration = () => {
  const { data } = useQuery(CONFIG_QUERY, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  return data && data.configuration;
};

export const WithFeatureFlag = (flag, Component, redirectSetting) => (props) => {
  const configuration = useConfiguration();
  if (!configuration) return null;

  const { features, settings } = configuration;
  const canAccess = features[flag];

  if (!canAccess) {
    if (typeof redirectSetting === 'string' && settings[redirectSetting]) {
      return <Redirect to={settings[redirectSetting]} />;
    } else if (redirectSetting) {
      const Fallback = redirectSetting;
      return <Fallback {...props} />;
    }
    return <PageNotFound />;
  }

  return <Component {...props} />;
};
